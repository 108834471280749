<template>
  <div>
    <div class="payment-detail-content">
      <div class="heading">
        <div class="left-heading text-start">
          <h2>Project Details</h2>
        </div>
      </div>
      <div class="box-content w-50">
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Project name</div>
          <div class="flex-container">{{ name }}</div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Chatwork room</div>
          <div class="flex-container">
            <td class="pt-3 pb-3"><a :href="chatworkLink" target="_blank" style="text-decoration: underline; color: #0d6efd">{{ chatworkRoom }}</a></td>
          </div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Division</div>
          <div class="flex-container">{{ divisionName }}</div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Created date</div>
          <div class="flex-container">{{ createdDate }}</div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Remind</div>
          <div class="flex-container">{{ remind }}</div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Total messages to bot</div>
          <div class="flex-container">{{ totalMessageToBot }}</div>
        </div>
        <div
          class="
            flex-container flex-wrap flex-space-between flex-middle
            box-row
          "
        >
          <div class="flex-container">Total message not replied</div>
          <div class="flex-container">{{ totalMessageNotReply }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as _ from "lodash";
export default {
  name: "ProjectDetail",
  data() {
    return {
      id: null,
      name: '',
      chatworkRoom: '',
      divisionName: '',
      createdDate: '',
      remind: '',
      totalMessageToBot: '',
      totalMessageNotReply: '',
      chatworkLink: '',
    };
  },
  mounted() {},
  created() {
    this.getProjectDetail();
  },
  methods: {
    getProjectDetail() {
      this.$store
        .dispatch("projects/getProjectDetail", this.$route.params.id)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.name = response.name;
          var match = /#!rid(.*)/.exec(response.chatworkRoomUrl);
          this.chatworkLink = response.chatworkRoomUrl;
          this.chatworkRoom = !_.isEmpty(match) ? match[1] : response.chatworkRoomUrl;
          this.divisionName = response.divisionName;
          this.createdDate = moment(response.createdAt).format("YYYY-MM-DD");
          this.remind = response.timeMention,
          this.totalMessageToBot = response.totalMessageBot;
          this.totalMessageNotReply = response.totalMessageNotReply;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/projects/detail.scss";

.btn-payback {
  width: 90px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background: #000000;
  border-radius: 8px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.box-content {
  width: 429px;
  background: #ffffff;
  border: 1px solid #ecf1f3;
  box-sizing: border-box;
  box-shadow: 3px 0px 10px rgba(223, 223, 223, 0.3);
  border-radius: 10px;
  margin: 20px auto;
  padding: 25px 30px;
}

.w-100 {
  width: 100%;
}

.box-row {
  height: 52px;
}

.flex-container {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-middle {
  align-items: center;
}

.header-content-failed {
  margin-top: 10px;
}

.content-failed {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  background: #eaeaea;
  padding: 10px;
  border-radius: 8px;
}
</style>